<template>
  <v-card v-if="timeline" color="grey lighten-4" flat>
    <v-card-text v-if="!timeline.rounds.length" class="pa-12 text-center black--text">
      <h1 v-if="!timeline.division.adults">Booo!</h1>
      <h1 v-if="!timeline.division.adults"><v-icon x-large color="black">fas fa-frown</v-icon></h1>
      <h1>There is no published schedule yet</h1>
    </v-card-text>
    <v-card-text class="pa-1" v-else>
      <v-row dense v-for="(round, i) in timeline.rounds" :key="i">
        <v-col cols="12">
          <v-card>
            <v-toolbar color="color2 color2Text--text" flat>
              <v-toolbar-title>
                {{round.title}}{{ round.subtitle ? ` - ${round.subtitle}` : '' }}
                <div class="caption">{{round.description}}</div>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip left>
                <span>Jump to {{round.subtitle || round.title}}</span>
                <template v-slot:activator="{ on }">
                  <v-btn color="color1 color1Text--text" fab small v-on="on" @click.stop="goto(round.to)">
                    <v-icon small>fas fa-caret-right</v-icon>
                  </v-btn>
                </template>
              </v-tooltip>
            </v-toolbar>
            <v-card-text class="pa-0 pr-2">
              <v-timeline dense>
                <v-timeline-item
                  :color="item.status ? 'green' : 'red'"
                  v-for="(item, i) in round.matches"
                  :key="i"
                  fill-dot
                >
                  <template v-slot:icon>
                    <v-icon  color="white">
                      {{item.what === 'Play' ? 'fal fa-volleyball-ball': item.what === 'Ref' ? 'far fa-whistle' : 'fas fa-loveseat'}}
                    </v-icon>
                  </template>
                  <v-card class="elevation-3">
                    <v-container fluid class="py-1">
                      <v-row dense>
                        <v-col class="py-0 d-flex align-center justify-space-between" cols="12">
                          <div>
                            <strong>{{item.what}} {{item.props.join(' | ')}}</strong>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row dense v-if="item.what === 'Play'">
                        <v-col cols="12" class="text-truncate py-0">
                          vs. {{teamName(item.who) | formatArray}}
                        </v-col>
                        <v-col cols="12" class="py-0">
                          <div v-if="item.wl">
                            {{item.wl}}{{item.scores.find(f => f === '0-0') ? '' : `: ${item.scores.join(', ')}`}}
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-timeline-item>

              </v-timeline>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['team'],
  computed: {
    ...mapGetters(['tournament', 'getRoundJProps', 'getTeam']),
    none () {
      return this.tournament.publicRounds.length === 0
    },
    timeline () {
      const timeline = this.tournament.getTimeline(this.team.id)
      timeline.rounds.forEach(r => {
        const p = this.getRoundJProps(r.id)
        if (r.to && r.to.params) r.to.params.divisionId = timeline.division.id
        if (p && p.noRefs) {
          r.matches = r.matches.filter(f => f.what !== 'Ref')
        }
        r.matches.forEach(m => {
          m.props = []
          m.roundN && m.props.push(`Round ${m.roundN}`)
          m.when && !r.hideTimes && m.props.push(m.when.format('ddd LT'))
          m.where && m.props.push(`Court ${m.where}`)
        })
      })

      return timeline
    },
    isLeague () {
      return this.timeline.division.isLeague
    }
  },
  methods: {
    goto (to) {
      this.$router.push(to)
      this.$emit('close-click')
    },
    teamName (ids) {
      return ids.map(id => {
        const t = this.getTeam(id)
        return t && t.name
      }).filter(f => !!f)
    }
  }
}
</script>

<style>
.v-card__text {
  line-height: initial !important;
}
</style>
